import React, { useEffect, useState } from 'react';
import { useSignupStore } from '../../reducers';
import { ErrorBlockText } from '../../components/widget-components';
import { Checkbox } from '../../components/Checkbox';
import {
  generateVerificationLink,
  updateUserCurrentFlow,
  setOliuDesktopFlow
} from '../../requests/signup';
import { Link } from 'react-router-dom';
import QRCode from 'react-qr-code';
import { useNavigate } from 'react-router-dom';
import * as Separator from '@radix-ui/react-separator';
import { FormStates } from './FormStateMachine';
import { isFirefox, isMobile } from '../../utils/oliuUtil';

export const OliuCheck = () => {
  const [urlLoaded, setUrlLoaded] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string | null>('');
  const [redirectUrl, setRedirectUrl] = useState('test');
  const [authToken, setAuthToken] = useState('');
  const [redirected, setRedirected] = useState<boolean>(false);
  const useIframe =
    import.meta.env.VITE_OLIU_MODE_IFRAME === 'TRUE' ? true : false;
  const store = useSignupStore();
  const [sessionId, setSessionId] = [
    useSignupStore.use.sessionId(),
    useSignupStore.use.setSessionId(),
  ];
  let [email] = [useSignupStore.use.email()];
  if (email.length === 0) {
    console.log("READ EMAIL FROM LOCAL STORAGE");
    email = localStorage.getItem('email') || '';
  }
  const oliuVerifyType = useSignupStore.use.oliuVerifyType();
  const navigate = useNavigate();
  const isUserOnFirefox = isFirefox();
  const isUserOnMobile = isMobile();

  const fireEvent = (e: React.MouseEvent<HTMLElement>) => {
    setRedirected(true);
    setUrlLoaded(false);
  };

  const getOliuLink = () => {
    generateVerificationLink(email, oliuVerifyType).then((resp) => {
      if (resp) {
        if (resp.url) {
          let redirUrl: string = resp.url || '';
          setSessionId(resp.sessionId || '')
          if (isUserOnMobile && !useIframe) {
            console.log("Using mobile NONIFRAME");
            setRedirected(false);
            setUrlLoaded(false);
            window.location.replace(redirUrl);
          } else {
            setUrlLoaded(true);
            setRedirectUrl(redirUrl);
          }
        } else if (resp.errorMessage) {
          setErrorMessage('Unfortunately we were unable to process your request at this time. Please try again later or contact us at support@paytrie.com');
        }
      }
    })
  };

  useEffect(() => {
    const errorRedirect = import.meta.env.VITE_SIGNUP_KYC_ERR_REDIRECT;

    if (isUserOnMobile) {
      if (useIframe) {
        console.log("Using mobile IFRAME");
        updateUserCurrentFlow(email, 'oliu_flow');
        navigate('/OliuIframe');
      }  else {
        updateUserCurrentFlow(email, 'oliu_flow');
        getOliuLink();
      }
    } else {
      if (email.length > 0) {
        if (!useIframe) {
          getOliuLink();
          updateUserCurrentFlow(email, 'oliu_flow');
        } else {
          setUrlLoaded(true);
          setRedirectUrl("REDIRECTIFRAME")
        }
      } else {
        navigate(errorRedirect);
      }
    }
  }, []);

  return (
    <div className="flex flex-col gap-5">
      {!urlLoaded && !redirected && (
        <div className="flex justify-between">Redirecting you to Oliu for verification. This will only take a moment.</div>
      )}

      {redirected && (
        <div className="flex justify-between">You can close this window.</div>
      )}

      {errorMessage && (
        <ErrorBlockText>{errorMessage}</ErrorBlockText>
      )}

      {redirectUrl.length > 4 && (
        <div>
          <div
            className="text-xl font-bold uppercase"
            style={{ paddingBottom: '1em' }}
          >
            {oliuVerifyType === 'bank' ? 'BANK' : 'ID'} VERIFICATION
          </div>

          <div className="flex flex-col items-left gap-6">
            <div style={{ paddingTop: '3em' }}>
              Scan the QR code to complete verification on your phone
            </div>
            <div className="flex">
              <QRCode value={redirectUrl} />
            </div>
          </div>

          {!isUserOnFirefox && (
            <>
              {!useIframe && (
                <div style={{textAlign: 'left', paddingTop: '50px', paddingBottom: '20px'}}>
                  <a
                  style={{textDecoration: 'underline', cursor: 'pointer'}}
                  onClick={() => {
                    //setOliuDesktopFlow(sessionId, true);
                    window.location.href = redirectUrl;
                  }}
                  >
                    Begin verification on current device
                  </a>
                </div>
              )}
              {useIframe && (
                <div style={{textAlign: 'center', paddingTop: '50px', paddingBottom: '20px'}}>
                  <a
                  style={{textDecoration: 'underline', cursor: 'pointer'}}
                  onClick={() => {
                    //setOliuDesktopFlow(sessionId, true);
                    navigate('/OliuIframe');
                  }}
                  >
                    Begin verification on current device
                  </a>
                </div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};
