import { FormEvent, useState } from 'react';
import { Select, SelectItem } from './DocumentTypeSelect';
import { FileUploader } from './FileUploader';
import { Button } from '../../../components/Button/Button';
import { TextInput } from '../../../components/widget-components';
import { ImageData } from '../../../requests/signup';

const legalIDOptions = [
  'Canadian Passport',
  'Non-Canadian Passport',
  "Provincial Driver 's License",
  'Provincial ID Card',
  'Permanent Resident Card',
];

type UploadIDProps = {
  onConfirm: (
    fileData: ImageData,
    accountType: string,
    idNumber: string
  ) => void;
};

export const UploadID = ({ onConfirm }: UploadIDProps) => {
  const [idType, setIdType] = useState('');
  const [idNumber, setIdNumber] = useState('');
  const [error, setError] = useState('');
  const [fileData, setFileData] = useState<ImageData | null>(null);

  const handleSuccess = (fileData: ImageData) => {
    setFileData(fileData);
    setError('');
  };

  const handleConfirm = (e: FormEvent) => {
    e.preventDefault();
    if (fileData) {
      onConfirm(fileData, idType, idNumber);
    }
  };

  return (
    <form className="flex flex-col gap-5" onSubmit={handleConfirm}>
      <div>
        Upload a photo of your passport or driver&apos;s license. Make sure all
        details are legible and that corners of the document are visible in the
        photo.
      </div>
      <div className="md:px-10">
        <Select onValueChange={(val) => setIdType(val.toLowerCase())}>
          {legalIDOptions.map((docType) => (
            <SelectItem key={docType} value={docType}>
              {docType}
            </SelectItem>
          ))}
        </Select>
      </div>
      <div>
        <TextInput
          autoFocus
          placeholder="ID Number"
          onChange={(e) => setIdNumber(e.target.value)}
        />
      </div>
      {error && <div className="text-rose-600">{error}</div>}
      {fileData && (
        <div className="flex justify-center invisible md:visible">
          <img src={fileData.url} width={100} />
        </div>
      )}
      {!fileData && (
        <div className="hidden md:flex justify-center">
          <div className="h-[150px] w-[100px] border-dashed border border-gray-300 dark:border-neutral-200 flex justify-center items-center px-2 py-2">
            Image
          </div>
        </div>
      )}
      <FileUploader onUploadComplete={handleSuccess} onError={setError} customButtonText="" showUploadIcon={true} />
      <Button
        type="submit"
        disabled={!fileData || !!error || !idType || !idNumber}
      >
        Confirm
      </Button>
    </form>
  );
};
