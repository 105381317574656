import { HamburgerMenuIcon, MoonIcon, SunIcon } from '@radix-ui/react-icons';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { useState, useEffect } from 'react';

export const DarkModeMenu = () => {
  const getInitialMode = () => {
    const savedMode = localStorage.getItem('darkMode');
    if (savedMode !== null) {
      return savedMode === 'true';
    }
    return window.matchMedia('(prefers-color-scheme: dark)').matches;
  };

  const [darkMode, setDarkMode] = useState(getInitialMode);

  const toggleDarkMode = () => {
    const newMode = !darkMode;
    setDarkMode(newMode);
    localStorage.setItem('darkMode', String(newMode));
    document.documentElement.classList.toggle('dark', newMode);
  };

  useEffect(() => {
    const syncDarkModeAcrossTabs = (event: StorageEvent) => {
      if (event.key === 'darkMode') {
        setDarkMode(event.newValue === 'true');
      }
    };
    window.addEventListener('storage', syncDarkModeAcrossTabs);
    return () => window.removeEventListener('storage', syncDarkModeAcrossTabs);
  }, []);

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <button className="IconButton" aria-label="toggle dark mode">
          <HamburgerMenuIcon className="w-8 h-8" />
        </button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content
          className="bg-white dark:bg-pebble min-w-[120px] p-3 shadow-md rounded"
          sideOffset={5}
        >
          <DropdownMenu.CheckboxItem
            className="flex items-center space-x-2 cursor-pointer"
            checked={darkMode}
            onCheckedChange={toggleDarkMode}
          >
            {darkMode ? (
              <>
                <SunIcon className="w-5 h-5" /> 
              </>
            ) : (
              <>
                <MoonIcon className="w-5 h-5" /> 
              </>
            )}
          </DropdownMenu.CheckboxItem>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};
