import {
  InteracLabel,
  LoginHeader,
  PoweredByLabel,
} from '../../components/widget-components';
import { Stepper } from './Stepper';
import { SignupPanel } from '../../panels/signup/SignupPanel';

const isIframe = window.self !== window.top; 

export const Signup = () => {
  const Footer = () => {
    return (
      <div className="flex flex-col-reverse justify-center md:flex-row-reverse md:justify-between">
        <PoweredByLabel />
      </div>
    );
  };
  return (
    <div className="flex flex-col md:flex-row gap-4 max-w-[730px] mx-auto min-h-full relative">
      <LoginHeader>
        <Stepper />
      </LoginHeader>
        <div className="flex flex-col flex-grow relative">
        <SignupPanel />
      {isIframe ? (
        <div
          className="p-4"
          style={{
            position: 'absolute',
            top: 'calc(700px - 200px)', 
            width: '100%',
            textAlign: 'center',
          }}
        >
          <PoweredByLabel />
        </div>
      ) : (
        <div className="mt-auto flex justify-center p-4">
          <PoweredByLabel />
        </div>
      )}
      </div>
    </div>
  );
};
