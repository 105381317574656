import { useEffect } from 'react';
import { Pages, usePageStateStore } from '../../reducers';
import { Button } from '../../components/Button/Button';
import { useNavigate } from 'react-router-dom';
import { Stepper } from '../../pages/Signup/Stepper/Stepper';
import {
  PaytrieTopIcon,
  PoweredByLabel,
  LoginHeader,
} from '../../components/widget-components';

export const CompleteRegistrationStandard = () => {
  const goToPage = usePageStateStore.use.goToPage();
  const navigate = useNavigate();
  const redirectURL = import.meta.env.VITE_COMPLETE_REDIRECT_URL;
  const redirectCustom = (import.meta.env.VITE_CUSTOM_COMPLETE_REDIRECT === 'TRUE' ) ? true : false;

  const returnToHome = () => {
    navigate('/EasyLogin');
  }

  return (
    <div className="flex flex-col md:flex-row">
    <LoginHeader>
      <Stepper />
    </LoginHeader>
        <div className="relative p-5 lg:py-20 w-full">
        <PaytrieTopIcon className="absolute top-5 right-5 hidden md:block" />
        <div className="flex-grow mb-5">
          <div className="flex flex-col gap-5">

          <div className="text-lg font-bold uppercase">Thank you for registering with Paytrie</div>
            <br />
            <div className='mb-16'>
            Please allow up to 24 hours for us to verify your identity. If we require more information, we will contact you by email.
            </div>
            <Button onClick={() => {redirectCustom ? window.location.href=redirectURL: returnToHome()}}>
                {import.meta.env.VITE_COMPLETE_REDIRECT_TEXT}
            </Button>

          </div>

        </div>

      </div>
    </div>
  ); 


};
