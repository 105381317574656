import PaytrieWidget from './PaytrieWidget';
import WelcomePanel from './panels/login/WelcomePanel';
import EmailLoginPanel from './panels/login/EmailLoginPanel';
import PasswordLoginPanel from './panels/login/PasswordLoginPanel';
import KeyLoginPanel from './panels/login/KeyLoginPanel';
import LoginVerifyPanel from './panels/login/LoginVerifyPanel';
import OliuIframe from './panels/signup/OliuIFrame';
import { createBrowserRouter, RouterProvider} from 'react-router-dom'
import { Signup } from './pages/Signup';
import { BuySell } from './pages/Buy';
import { Buy } from './pages/Buy/Transact/Buy';
import { DashboardTrade } from './pages/Dashboard/DashboardTrade';
import { DashboardHistory } from './pages/Dashboard/DashboardHistory';
import { DashboardProfile } from './pages/Dashboard/DashboardProfile';
import { CompleteRegistration } from './panels/signup/CompleteRegistration';
import { CompleteRegistrationStandard } from './panels/signup/CompleteRegistrationStandard';
import { RegistrationFailure } from './panels/signup/RegistrationFailure';
import OliuRedirectSuccess from './panels/signup/OliuRedirectSuccess';
import OliuRedirectFailed from './panels/signup/OliuRedirectFailed';
import OrderStatus from './pages/Orders/OrderStatus';
import Confirmation from './pages/Orders/Confirmation'
import { ProtectedRoute } from './components/ProtectedRoute';
import Upload from './pages/Upload/Upload';
import Semitest from './pages/SemiDesignTest/SemiTest';

const router = createBrowserRouter(
[
  {
    path: "/",
    element: <Signup />
  },
  // {
  //   path: "/Welcome",
  //   element: <WelcomePanel />
  // },
  {
    path: "/Signup",
    element: <Signup />
  },
  {
    path: "/SignupEmail",
    element: <Signup />
  },
  {
    path: "/SignupVerifyEmail",
    element: <Signup />
  },
  {
    path: "/SignupPhone",
    element: <Signup />
  },  
  {
    path: "/SignupVerifySMS",
    element: <Signup />
  },
  {
    path: "/SignupDisclosure",
    element: <Signup />
  },
  {
    path: "/SignupUserInfo",
    element: <Signup />
  },
  {
    path: "/SignupOccupation",
    element: <Signup />
  },
  {
    path: "/SignupKYC",
    element: <Signup />
  },
  {
    path: "/SignupPassword",
    element: <Signup />
  },
  // {
  //   path: "/EasyLogin",
  //   element: <EmailLoginPanel landing={false} />
  // },
  // {
  //   path: "/LandingLogin",
  //   element: <EmailLoginPanel landing={true} />
  // },
  // {
  //   path: "/Login",
  //   element: <PasswordLoginPanel />
  // },
  // {
  //   path: "/KeyLogin",
  //   element: <KeyLoginPanel />
  // },
  // {
  //   path: "/LoginVerify",
  //   element: <LoginVerifyPanel />
  // },
  // {
  //   element: <ProtectedRoute />,
  //   children: [
  //   {
  //     path: "/Transactions",
  //     element: <Buy />
  //   },
  //   {
  //     path: "/Dashboard",
  //     element: <DashboardTrade />
  //   },
  //   {
  //     path: "/History",
  //     element: <DashboardHistory />
  //   },  
  //   {
  //     path: "/Profile",
  //     element: <DashboardProfile />
  //   }, 
  //   ] 
  // }, 
  {
    path: "/OliuIframe",
    element: <OliuIframe />
  },
  {
    path: "/OliuComplete",
    element: <CompleteRegistration />
  },
  {
    path: "/OliuComplete24",
    element: <CompleteRegistrationStandard />
  },
  {
    path: "/Failed",
    element: <RegistrationFailure />
  },
  {
    path: "/RedirectSuccess",
    element: <OliuRedirectSuccess />
  },
  {
    path: "/RedirectFailed",
    element: <OliuRedirectFailed />
  },
  // {
  //   path: "/Status",
  //   element: <OrderStatus />
  // },
  // {
  //   path: "/Confirmation",
  //   element: <Confirmation />
  // },
  // {
  //   path: "/Upload",
  //   element: <Upload />
  // },
  // {
  //   path: "/Semitest",
  //   element: <Semitest />
  // },
  {
    path: "*",
    element: <Signup />
  }

]
)


const App = () => {
  return (
    <div className="w-full md:flex md:justify-center md:items-center">
      <div className="lg:max-w-[730px] lg:w-[730px]">
        {/* <PaytrieWidget /> */}
        <RouterProvider router={router} />
      </div>
    </div>
  );
};

export default App;
