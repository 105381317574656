import { FormEvent, useEffect, useState } from 'react';
import { ErrorBlockText, TextInput } from '../../components/widget-components';
import { sendSMS, updateUserCurrentFlow } from '../../requests/signup';
import { useSignupStore } from '../../reducers';
import { Button } from '../../components/Button/Button';
import { submitOnEnter } from '../../utils/keyboard';
import { LoadingIcon } from '../../components/LoadingIcon';
import { useNavigate } from 'react-router-dom';
import { FormStates } from './FormStateMachine';

const PHONE_PATTERN =
  /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;

export default function SignupContentPhoneNumber() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [phoneNumber, setLocalPhoneNumber] = useState('');
  const [errorMessage, setErrorMessage] = useState<string | null>('');
  const email = useSignupStore.use.email();
  const setFormState = useSignupStore.use.nextState();
  const setPhoneNumber = useSignupStore.use.setPhoneNumber();

  useEffect(() => {
    // On page load if there is query param for phone, then set it
      setLocalPhoneNumber(localStorage.getItem('phone') || '');
  }, []);

  async function sendPhoneNumberVerification(e: FormEvent): Promise<void> {
    e.preventDefault();
    if (PHONE_PATTERN.test(phoneNumber)) {
      setIsLoading(true);

      const [err] = await sendSMS(email, phoneNumber);
      if (err === null) {
        setErrorMessage('');
        setPhoneNumber(phoneNumber);
        localStorage.setItem('phone', phoneNumber);
        setFormState();
        // set flow
        await updateUserCurrentFlow(email, FormStates.PHONE);
        navigate("/SignupVerifySMS")
      } else {
        if (err === 'Number already in use') {
          setErrorMessage('The phone number is already in use. Please enter a valid phone number.');
        } else if (err === 'Failed sending SMS code' || err === 'Invalid phone number') {  
          setErrorMessage('The phone number is either invalid or a VOIP number. Please enter a valid phone number.');
        } else {
          setErrorMessage('Unfortunately we were unable to process your request at this time. Please try again later or contact us at support@paytrie.com');
        }
      }
      setIsLoading(false);
    } else {
      setErrorMessage('Invalid phone number. Please check and try again');
      setIsLoading(false);
    }
  }

  if (isLoading) {
    return (
      <div className="my-auto flex justify-center">
        <LoadingIcon />
      </div>
    );
  }

  return (
    <form onSubmit={sendPhoneNumberVerification}>
      <div className="flex flex-col gap-5">
        <div className="text-lg font-bold uppercase">
          ENTER YOUR PHONE NUMBER
        </div>
        <div className="text-base">
          We'll send you a code to verify your account
        </div>
        <div className='mb-4'>
        <TextInput
          autoFocus
          aria-label="Mobile number"
          placeholder="(000) 000 0000"
          type="tel"
          inputMode="numeric"
          maxLength={10}
          onChange={(e) => setLocalPhoneNumber(e.target.value.replace(/[^0-9\+]/g, ''))}
          onKeyDown={submitOnEnter(sendPhoneNumberVerification)}
          value={phoneNumber}
        />
        </div>
        <ErrorBlockText>{errorMessage}</ErrorBlockText>
        <Button type="submit" disabled={phoneNumber.length < 10}>Continue</Button>
      </div>
      <br />
      <br />
    </form>
  );
}
