import { useEffect, useState } from 'react';


export default function OliuRedirectSuccess() {

    useEffect(() => {
        //window.parent?.postMessage(`redirectURL ${window.location.protocol}//${window.location.hostname}/OliuComplete24`)
        window.location.replace(`${window.location.protocol}//${window.location.hostname}/OliuComplete24`);
      }, []);


return (
    <div>
        Redirecting to Paytrie. If you are not redirected automatically, click here: <a style={{textDecoration: 'underline'}} href={`${window.location.protocol}//${window.location.hostname}/OliuComplete24`}>{window.location.protocol}//{window.location.hostname}/OliuComplete24</a>
    </div>
)
}
