import { immerable } from 'immer';
import { QuoteResponse } from '../../requests/quote';
import {
  FiatCurrency,
  NetworkValue,
  Networks,
  StableCoinValue,
  StableCoins,
  BUY_MAX_LIMIT_AMT, SELL_MAX_LIMIT_AMT, MIN_LIMIT_AMT, FEERATE, FEERATECADC, MINCADCFEE, MINUSDCFEE
} from '../../utils/currencies';
import { isAddress } from 'web3-validator';
import { TransactionType, TransactionValue } from '../../reducers/transactionReducer';


export class ConversionController {
  [immerable] = true;
  private quoteData: QuoteResponse;
  private inverseRate: number;

  constructor(quoteData: QuoteResponse) {
    this.quoteData = quoteData;
    this.inverseRate = 1 / quoteData.cadusd; // Multiply by this number when buying something quoted in USD
  }

  async updateQuote(quoteData: QuoteResponse) {
    this.quoteData = quoteData;
  }

  get quoteId() {
    return this.quoteData.id;
  }

  get gasId() {
    return this.quoteData.gasId;
  }

  getGasFee(network: NetworkValue): number {
    if (network === Networks.ethereum) {
      return this.quoteData.gasFee;
    }
    return 0;
  }

  getGasPrice() {
    return this.quoteData.gasPrice;
  }

  getCadUsdConversion() {
    return this.quoteData.cadusd;
  }

  getLastQuotedTimestamp() {
    return new Date(this.quoteData.timestamp);
  }

  // must be in CAD
  getPaytrieFee(amount: number, coin: StableCoinValue): number {
    if (coin === StableCoins.CADC) {
      return Math.max(amount * FEERATECADC, 2);
    } else {
      return Math.max(amount * FEERATE, 5);
    }
  }

  getInversePaytrieFee(amount: number, coin: StableCoinValue): number {
    if (coin === StableCoins.CADC) {
      const expectedFee = (amount / 1 - this.quoteData.fee) * this.quoteData.fee;
      return Math.max(expectedFee, 2);
    } else {
      const expectedFee = (amount / 1 - this.quoteData.fee) * this.quoteData.fee;
      return Math.max(expectedFee, 5);
    }
  }

  convert(amount: number, coin: StableCoinValue): number {
    let rate: number;
    switch (coin) {
      case FiatCurrency.CAD:
      case StableCoins.CADC:
        rate = 1;
        break;
      case FiatCurrency.USD:
        rate = amount * this.inverseRate;
        break;
      case StableCoins.USDC:
        rate = this.quoteData.usdusdc * this.inverseRate;
        break;
      // case StableCoins.BUSD:
      //   rate = this.quoteData.usdbusd * this.inverseRate;
      //   break;
      // case StableCoins.DAI:
      //   rate = this.quoteData.caddai;
      //   break;
      // case StableCoins.USDT:
      //   rate = this.quoteData.usdusdt * this.inverseRate;
      //   break;
      default:
        return NaN;
    }
    const coinAmount = rate * amount;

    return coinAmount;
  }

  // convertAmount(
  //   amount: number,
  //   coin: StableCoinValue,
  //   network: NetworkValue,
  //   transactionType: TransactionValue,
  //   sideChanging: string,
  // ) {
  //   console.log("AMOUNT:", amount);
  //   console.log("COIN:", coin);
  //   console.log("NETWORK:", network);
  //   console.log("TRANSACTIONTYPE:", transactionType);
  //   console.log("SIDECHANGING:", sideChanging);

    

  // }

  // 
  convertFiatToCoinAmount(
    amount: number,
    coin: StableCoinValue,
    network: NetworkValue,
    transactionType: TransactionValue,
    sideChanging: string,
  ) {
    // console.log("AMOUNT:", amount);
    // console.log("COIN:", coin);
    // console.log("NETWORK:", network);
    // console.log("TRANSACTIONTYPE:", transactionType);
    // console.log("SIDECHANGING:", sideChanging);

    // Calculate FX fee first
    let fxRate: number;
    if (coin === StableCoins.CADC) {
      fxRate = 1;
    } else if (coin === StableCoins.USDC) {
      fxRate = this.quoteData.cadusd;
    } else {
      fxRate = 0;
    } 

    // Calculate fees
    if (sideChanging === 'left') { // LSV CAD  RSV USDC/CADC, changing amount on LSV, calculate for RSV
      
      if (amount < 0) {
        return Math.abs(amount);
      } else if (isNaN(amount)) {
        return 0;
      } else if (amount < MIN_LIMIT_AMT) {
        return 0;
      }

      if (coin === StableCoins.CADC) { 
        console.log("CONVERT CAD TO CADC");
        if (amount * FEERATECADC > MINCADCFEE) {
          console.log("CASE1");
          return (amount - (amount * FEERATECADC) - this.getGasFee(network));
        } else {
          console.log("CASE2");
          return (amount - MINCADCFEE - this.getGasFee(network));
        }
      } else { 
        console.log("CONVERT CAD TO USDC");
        if (amount * FEERATE > MINUSDCFEE) {
          console.log("CASE3");
          return ((amount * (1 - FEERATE)) - this.getGasFee(network)) / fxRate;
        } else {
          console.log("CASE4");
          return (amount - MINUSDCFEE - this.getGasFee(network)) / fxRate;
        }
      }
    } else if (sideChanging === 'right') { // LSV USDC/CADC  RSV CAD, changing amount on RSV, calculate for LSV
      if (amount < 0) {
        return Math.abs(amount);
      } else if (isNaN(amount)) {
        return 0;
      } else if (amount < MIN_LIMIT_AMT) {
        return 0;
      }

      if (coin === StableCoins.CADC) { 
        console.log("CONVERTING CADC TO CAD");
        if (amount * FEERATECADC > MINCADCFEE) {
          console.log("CASE5");
          return (amount / (1 - FEERATECADC));
        } else {
          console.log("CASE6");
          return (amount + MINCADCFEE);
        }        
      } else { // If converting USDC to CAD
        console.log("CONVERTING USDC TO CAD");
        if (amount * FEERATE > MINUSDCFEE) {
          console.log("CASE7");
          return (amount / fxRate) / (1 - FEERATE);
        } else {
          console.log("CASE8");
          return (amount / fxRate) + MINUSDCFEE;
        }
      }
    } else {
      console.log("CASE9");
      return 0;
    }

  }

  // 
  convertCoinAmountToFiat(
    amount: number,
    currency: string,
    network: NetworkValue,
    transactionType: TransactionValue,
    sideChanging: string,
  ) {
    // console.log("AMOUNT:", amount);
    // console.log("COIN:", currency);
    // console.log("NETWORK:", network);
    // console.log("TRANSACTIONTYPE:", transactionType);
    // console.log("SIDECHANGING:", sideChanging);

    // Calculate FX fee first
    let fxRate: number;
    if (currency === StableCoins.CADC) {
      fxRate = 1;
    } else if (currency === StableCoins.USDC) {
      fxRate = this.quoteData.cadusd;
    } else {
      fxRate = 0;
    } 

    //return 0;
    if (sideChanging === 'left') { // LSV USDC/CADC  RSV CAD, changing amount on LSV, calculate for RSV
      
      let RSVtotal : number;
      
      if (amount < 0) {
        return Math.abs(amount);
      } else if (isNaN(amount)) {
        return 0;
      } 

      if (currency === StableCoins.CADC) {
        if (amount * FEERATECADC > MINCADCFEE) {
          console.log("CASE14");
          //return (amount * (1 - FEERATECADC));
          RSVtotal = (amount * (1 - FEERATECADC));
        } else {
          console.log("CASE15");
          //return (amount - MINCADCFEE);
          RSVtotal = (amount - MINCADCFEE);
        }  
      } else { 
        if (amount * FEERATE > MINUSDCFEE) {
          console.log("CASE16");
          //return (amount * (1 - FEERATE)) * fxRate;
          RSVtotal = (amount * (1 - FEERATE)) * fxRate;
        } else {
          console.log("CASE17");
          //return (amount - MINUSDCFEE) * fxRate;
          RSVtotal = (amount - MINUSDCFEE) * fxRate;
        }
      }

      // For these case you need to calculate RSV total and then check if its under MIN trading limit
      if (RSVtotal < MIN_LIMIT_AMT) {
        return 0
      } else {
        return RSVtotal;
      }

    } else if (sideChanging === 'right') { // LSV CAD  RSV USDC/CADC, changing amount on RSV, calculate for LSV
      
      let LSVtotal : number;

      if (amount < 0) {
        return Math.abs(amount);
      } else if (isNaN(amount)) {
        return 0;
      } 
      
      if (currency === StableCoins.CADC) {
        if (amount * FEERATECADC > MINCADCFEE) {
          console.log("CASE10");
          //return ((amount + this.getGasFee(network)) / (1 - FEERATECADC));
          LSVtotal = ((amount + this.getGasFee(network)) / (1 - FEERATECADC));
        } else {
          console.log("CASE11");
          //return (amount + MINCADCFEE + this.getGasFee(network));
          LSVtotal = (amount + MINCADCFEE + this.getGasFee(network));
        }  
      } else {
        if (amount * FEERATE > MINUSDCFEE) {
          console.log("CASE12");
          //return (((amount * fxRate) + this.getGasFee(network)) / (1 - FEERATE));
          LSVtotal = (((amount * fxRate) + this.getGasFee(network)) / (1 - FEERATE));
        } else {
          console.log("CASE13");
          //return ((amount * fxRate) + MINUSDCFEE + this.getGasFee(network));
          LSVtotal = ((amount * fxRate) + MINUSDCFEE + this.getGasFee(network));
        }
      }

      // For these case you need to calculate LSV total and then check if its under MIN trading limit
      if (LSVtotal < MIN_LIMIT_AMT) {
        return 0
      } else {
        return LSVtotal;
      }

    } else {
      return 0;
    }

  }

  format(amount: number, coin: StableCoinValue, precision = 2) {
    let conv = this.convert(amount, coin);
    return (1/conv).toFixed(precision);
  }
}

export function isValidWalletAddress(walletAddress: string) {
  return isAddress(walletAddress);
  // return walletAddress.length > 0;
}

export function getMaximumAllowedValue(transactionType: string) {
  if (transactionType === TransactionType.BUY) {
    return(BUY_MAX_LIMIT_AMT);
  } else {
    return (SELL_MAX_LIMIT_AMT);
  }
}

export function getMinimumAllowedValue(transactionType: string) {
  return MIN_LIMIT_AMT;
}