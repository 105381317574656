import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import GlobalStyle from './global-styles';
import './input.css';
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://623c716aaed40ac21c6afd6fa3d979d5@o4508286797283328.ingest.us.sentry.io/4508286831165440",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

if (import.meta.env.MODE === 'development') {
  // When development, setup the MSW.
  // import the worker (under the browser.ts file)
  import('./mocks/browser')
    .then(({ worker }) => {
      // Start the worker.
      worker.start({
        onUnhandledRequest: (request) => {
          if (request.url.pathname.includes('/src/assets/')) {
            return;
          }
          console.warn('Unhandled: %s %s', request.method, request.url.href);
        },
      });
    })
    .then(() => {
      // Render the application.
      root.render(
        <StrictMode>
          <GlobalStyle />
          <App />
        </StrictMode>
      );
    });
} else {
  // Render the application in production without the MSW.
  root.render(
    <>
      <GlobalStyle />
      <App />
    </> 
  );
}
