import { FormEvent, useState } from 'react';
import {
  ErrorBlockText,
  TextInput,
  LeftAlignSmallText,
} from '../../components/widget-components';
import { updateUserCurrentFlow } from '../../requests/signup';
import { useSignupStore } from '../../reducers';
import { Button } from '../../components/Button/Button';
import { submitOnEnter } from '../../utils/keyboard';
import { LoadingIcon } from '../../components/LoadingIcon';
import { useNavigate } from 'react-router-dom';
import { FormStates } from './FormStateMachine';

export default function SignupDisclosure() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const email = useSignupStore.use.email();

  if (isLoading) {
    return (
      <div className="my-auto flex justify-center">
        <LoadingIcon />
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-5">
      <div className="text-lg font-semibold uppercase">Disclosure</div>
      <div className="text-base">Before you trade:</div>

      <LeftAlignSmallText style={{ paddingBottom: '1em' }}>
        <ol className="list-decimal">
          <li>
            Cryptocurrencies are highly speculative and you may lose some or all
            of your money.
          </li>
          <li>
            Do your research to understand the risks. Paytrie offers{' '}
            <a
              href={import.meta.env.VITE_PAYTRIE_EDU_LINK_URL}
              target="_blank"
              style={{ textDecoration: 'underline' }}
            >
              educational resources
            </a>{' '}
            to help you learn more about cryptocurrencies.
          </li>
          <li>
            Protect yourself from frauds and scams, such as employment,
            investment, pig butchering, and romance scams.
          </li>
          <li>
            Cryptocurrency transactions cannot be reversed once completed.
          </li>
        </ol>
      </LeftAlignSmallText>
      <div className='mb-8'>
      <LeftAlignSmallText style={{ paddingBottom: '0em' }}>
        <p className="font-semibold">
          By tapping "Continue", you acknowledge having received, read, and
          understood these risks.{' '}
        </p>
      </LeftAlignSmallText>
      </div>

      <Button
        onClick={() => {
          // set flow
          updateUserCurrentFlow(email, FormStates.DISCLOSURE);
          navigate('/SignupUserInfo');
        }}
      >
        Continue
      </Button>
    </div>
  );
}
