import { ChangeEvent, useRef } from 'react';
import styled from 'styled-components';
import { useSignupStore } from '../../reducers';
import clsx from 'clsx';

export const StyledInput = styled.input`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px;
  margin-top: 10px;
  padding-bottom: 4px;
  width: 100%;
  background-color: transparent;
  gap: 14px;
  border: 0;
  border-bottom: 2px solid lightgrey;
  height: 32px;
  border-color: var(--rock);
  font-family: 'PP Object Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  user-select: none;
  box-shadow: none;
  outline: none;
  &:-webkit-autofill,
  &:-webkit-autofill:focus {
    transition:
      background-color 0s 600000s,
      color 0s 600000s !important;
  }
`;

export const BirthdayInput = () => {
  const { birthDay, setBirthDay } = useSignupStore();
  const inputRef = useRef<HTMLInputElement>(null);

  const formatDateInput = (input: string): string => {
    const numbers = input.replace(/\D/g, '');
    
    if (numbers.length <= 4) return numbers;
    if (numbers.length <= 6) return `${numbers.slice(0, 4)}-${numbers.slice(4)}`;
    return `${numbers.slice(0, 4)}-${numbers.slice(4, 6)}-${numbers.slice(6, 8)}`;
  };

  const handleDateChange = (e: ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;
    const cursorPosition = e.target.selectionStart || 0;
    const prevValue = birthDay || '';
    const numbers = input.replace(/\D/g, '');
    
    const formattedInput = formatDateInput(input);
    
    if (formattedInput.length <= 10) {
      setBirthDay(formattedInput);

      let newPosition = cursorPosition;

      if (numbers.length === 4 && prevValue.length === 3) {
        newPosition = 6;
      } else if (numbers.length === 6 && prevValue.length === 6) {
        newPosition = 9;
      } else if (formattedInput.length < prevValue.length) {
        newPosition = cursorPosition;
      } else {
        newPosition = cursorPosition + (formattedInput.length - prevValue.length);
      }
      //
      requestAnimationFrame(() => {
        if (inputRef.current) {
          inputRef.current.setSelectionRange(newPosition, newPosition);
        }
      });
    }
  };

  return (
    <StyledInput
      ref={inputRef}
      type="text"
      value={birthDay || ''}
      onChange={handleDateChange}
      //onBlur={() => setBirthDay(formatDateInput(birthDay || ''))}
      placeholder="YYYY-MM-DD"
      maxLength={10}
      className={clsx({
        'text-gray-400 dark:text-gray-400': !birthDay,
        'text-pebble dark:text-snow': birthDay,
        'focus:text-clementine dark:focus:text-sun': true
      })}
    />
  );
};