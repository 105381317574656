import { immer } from 'zustand/middleware/immer';
import { create } from 'zustand';
import { createSelectorFunctions } from 'auto-zustand-selectors-hook';

export const Pages = {
  SIGNUP: 'SIGNUP',
  SIGNUP_OLIU: 'SIGNUP_OLIU',
  ENROLLMENT_CHOOSER: 'ENROLLMENT_CHOOSER',
  OLIU_KYC: 'OLIU_KYC',
  PASSWORD_LOGIN: 'PASSWORD_LOGIN',
  EMAIL_LOGIN: 'EMAIL_LOGIN',
  KEY_LOGIN: 'KEY_LOGIN',
  CALC: 'CAlC',
  WELCOME: 'WELCOME',
  TRANSACT: 'TRANSACT',
  PROFILE: 'PROFILE',
} as const;

export type PageValue = (typeof Pages)[keyof typeof Pages];

type PageState = {
  page: PageValue;
  authToken: string | null;
  apiKey: string | null;
  email: string;
  tx_id: string;
  darkMode: boolean;
};

const initialState: PageState = {
  page: Pages.CALC,
  //authToken: sessionStorage.getItem('authToken'),
  authToken: localStorage.getItem('authToken'),
  apiKey: null,
  email: '',
  tx_id: '',
  darkMode: false,
};

type Actions = {
  goToPage: (page: PageValue) => void;
  submitTransaction: () => void;
  setApiKey: (key: string) => void;
  setAuthToken: (key: string) => void;
  setEmail: (email: string) => void;
  setTxId: (tx_id: string) => void;
  setDarkMode: (darkMode: boolean) => void;
  reset: () => void;
  clearAuthToken: () => void;
};

export const createPageStore = create<PageState & Actions>()(
  immer<PageState & Actions>((set) => ({
    ...initialState,
    goToPage: (page: PageValue) =>
      set((state) => {
        state.page = page;
      }),
    submitTransaction: () => {
      set((state) => {
        if (state.authToken !== null) {
          state.page = Pages.TRANSACT;
        } else {
          state.page = Pages.WELCOME;
        }
      });
    },
    setApiKey: (key: string) =>
      set((state) => {
        state.apiKey = key;
      }),
    setAuthToken: (token: string) => {
      set((state) => {
        //sessionStorage.setItem('authToken', token);
        localStorage.setItem('authToken', token);
        state.authToken = token;
      });
    },
    setTxId: (tx_id: string) => {
      set((state) => {
        state.tx_id = tx_id;
      });
    },
    clearAuthToken: () => {
      set((state) => {
        //sessionStorage.clear();
        localStorage.clear();
        state.authToken = null;
      });
    },
    reset: () => {
      set(initialState);
    },
    setEmail: (email: string) => {
      set((state) => {
        state.email = email;
      });
    },
    setDarkMode: (darkMode: boolean) => {
      set((state) => {
        state.darkMode = darkMode;
      });
    },
  }))
);
export const usePageStateStore = createSelectorFunctions(createPageStore);
