import { createGlobalStyle } from 'styled-components';
import PaytrieFont from './assets/PPObjectSans-Regular.otf';

const GlobalStyle = createGlobalStyle`
@font-face {
    font-family: 'PP Object Sans';
    src: url(${PaytrieFont}) format("opentype");;
 }
 font-size: 1rem
 height: 100%;
`;
export default GlobalStyle;
