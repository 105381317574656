import { useState, useCallback, FormEvent, useEffect } from 'react';
import {
  ErrorBlockText,
  TextInput,
  LeftAlignSmallText,
  CenterAlignedSmallText,
  TocSmallText,
} from '../../components/widget-components';

import { checkEmail, updateUserCurrentFlow } from '../../requests/signup';
import { useSignupStore } from '../../reducers';
import { Button } from '../../components/Button/Button';
import * as EmailValidator from 'email-validator';
import { isAxiosError } from 'axios';
import clsx from 'clsx';
import { LoadingIcon } from '../../components/LoadingIcon';
import { useNavigate } from 'react-router-dom';
import { FormStates } from './FormStateMachine';

export default function SignupContentEmail() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [email1, setEmail1] = useState('');
  const [email2, setEmail2] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showReferral, setShowReferral] = useState(false);
  const [referralCode, setReferralCode] = useState('');
  const [showFirefoxDialog, setShowFirefoxDialog] = useState(false);

  const setEmail = useSignupStore.use.setEmail();
  const nextState = useSignupStore.use.nextState();
  const setReferralCodeToStore = useSignupStore.use.setReferralCode();

  useEffect(() => {

    const urlParams = new URLSearchParams(window.location.search);
    const LSVParams = urlParams.get("leftSideValue");
    const LSLParams = urlParams.get("leftSideLabel");
    const RSLParams = urlParams.get("rightSideLabel");
    const emailParam = urlParams.get("email");
    const referralCodeParam = urlParams.get("ref");
    const phoneParam = urlParams.get("phone");
    const fNameParam = urlParams.get("firstName");
    const lNameParam = urlParams.get("lastName");
    const dob1Param = urlParams.get("dob1");
    const dob2Param = urlParams.get("dob2");
    const dob3Param = urlParams.get("dob3");
    const addr1Param = urlParams.get("address1");  
    const addr2Param = urlParams.get("address2"); 
    const cityParam = urlParams.get("city"); 
    const provParam = urlParams.get("province");   
    const postalParam = urlParams.get("postal");  

    if (LSVParams === '') {
      localStorage.setItem('leftSideValue', LSVParams || '');
    }
    if (LSLParams === '') {
      localStorage.setItem('leftSideLabel', LSLParams || '');
    }
    if (RSLParams === '') {
      localStorage.setItem('rightSideLabel', RSLParams || '');
    }
    if (emailParam === '') {
      localStorage.setItem('email', emailParam || '');
    }
    if (referralCodeParam === '') {
      localStorage.setItem('ref', referralCodeParam || '');
    }
    if (phoneParam === '') {
      localStorage.setItem('phone', phoneParam || '');
    }
    if (fNameParam === '') {
      localStorage.setItem('firstName', fNameParam || '');
    }
    if (lNameParam === '') {
      localStorage.setItem('lastName', lNameParam || '');
    }
    if (dob1Param === '') {
      localStorage.setItem('dob1', dob1Param || '');
    }
    if (dob2Param === '') {
      localStorage.setItem('dob2', dob2Param || '');
    }
    if (dob3Param === '') { 
      localStorage.setItem('dob3', dob3Param || '');
    }
    if (addr1Param === '') {
      localStorage.setItem('address1', addr1Param || '');
    }
    if (addr2Param === '') {
      localStorage.setItem('address2', addr2Param || '');
    }
    if (cityParam === '') {
      localStorage.setItem('city', cityParam || '');
    }
    if (provParam === '') {
      localStorage.setItem('province', provParam || '');
    }
    if (postalParam === '') {
      localStorage.setItem('postal', postalParam || ''); 
    }   

    // On page load if there is query param for email, then set it
    setEmail1(localStorage.getItem('email') || '');

    // On page load if there is query param for ref, then set it
    if (localStorage.getItem('ref')) { 
      setShowReferral(true);
      setReferralCode(localStorage.getItem('ref') || '');
    }
  }, []);

  const updateReferral = useCallback(
    () => setShowReferral(!showReferral),
    [showReferral, setShowReferral]
  );

  async function checkApiForEmail(e: FormEvent) {
    e.preventDefault();
    if (!EmailValidator.validate(email1)) {
      setErrorMessage('Must be a valid email address');
      return;
    }
    setIsLoading(true);
    const { response, error } = await checkEmail(email1, referralCode);
    if (response) {
      setErrorMessage('');
      setEmail(email1);
      localStorage.setItem('email', email1);
      setReferralCodeToStore(referralCode);
      nextState();
      // set flow
      await updateUserCurrentFlow(email1, FormStates.EMAIL);
      navigate("/SignupVerifyEmail")
    } else if (error && isAxiosError(error)) {
      if (error.status && error.status >= 500) {
        setErrorMessage('Paytrie services are not available at the moment');
      }
      setErrorMessage(
        'This email address may already be in use or not be eligible for Paytrie services'
      );
    } else {
      setErrorMessage(
        'An unexpected error has occurred, please try again later.'
      );
    }
    setIsLoading(false);
  }

  const handleInputClick = () => {
    if (navigator.userAgent.includes("Firefox")) {
      setShowFirefoxDialog(true);
    }
  };

  if (isLoading) {
    return (
      <div className="my-auto flex justify-center">
        <LoadingIcon />
      </div>
    );
  }

  return (
    <>
    <form className="flex flex-col gap-5" onSubmit={checkApiForEmail}>
      <div className="text-lg font-bold uppercase">SIGN UP</div>
      <div className="text-base">
        Enter the email associated with your Interac profile.
      </div>
      <TextInput
        name="email"
        aria-label="email"
        placeholder="Email"
        type="email"
        onChange={(e) => setEmail1(e.target.value)}
        value={email1}
        autoFocus
        onClick={handleInputClick} 
        readOnly={navigator.userAgent.includes("Firefox")}
      />
      <div className='mb-[-2rem]'>
      <LeftAlignSmallText>
        <button type="button" onClick={updateReferral}>I have a referral code</button>
      </LeftAlignSmallText>
      </div>

      {showReferral && (
        <TextInput
          placeholder="Referral Code"
          name="ReferralCode"
          aria-label="verifyEmail"
          type="text"
          onChange={(e) => setReferralCode(e.target.value)}
          value={referralCode}
        />
      )}
      <div className='mb-2'>
      <TocSmallText>
        By signing up, you agree to Paytrie's{' '}
        <a
          href={import.meta.env.VITE_PAYTRIE_TOC_URL}
          target="_blank"
          style={{ textDecoration: 'underline' }}
        >
          Terms and Conditions
        </a>{' '}
        and{' '}
        <a
          href={import.meta.env.VITE_PAYTRIE_PRIVACY_URL}
          target="_blank"
          style={{ textDecoration: 'underline' }}
        >
          Privacy Statement
        </a>
        , and Oliu's{' '}
        <a
          href={import.meta.env.VITE_OLIU_TOC_LINK_URL}
          target="_blank"
          style={{ textDecoration: 'underline' }}
        >
          Terms and Conditions
        </a>{' '}
        and{' '}
        <a
          href={import.meta.env.VITE_OLIU_TOC_PRIVACY_URL}
          target="_blank"
          style={{ textDecoration: 'underline' }}
        >
          Privacy Statement
        </a>
        . By providing your email, you consent to receiving communications from Paytrie. You can opt-out anytime.
      </TocSmallText>
      </div>
      <ErrorBlockText>{errorMessage}</ErrorBlockText>      
      <Button type="submit" disabled={!email1.length}>Continue</Button>

      {/* <LeftAlignSmallText>
        Already have an account?{' '}
        <button
          type="button"
          tabIndex={0}
          className="cursor-pointer underline hover:text-clementine dark:hover:text-sun outline-offset-4 focus:text-clementine dark:focus:text-sun"
          onClick={() => {
            navigate('/EasyLogin');
          }}
        >
          Log In
        </button>
        .
      </LeftAlignSmallText> */}
    </form>
    {showFirefoxDialog && (
      <div
        className="fixed inset-0 bg-black bg-opacity-50"
        style={{ zIndex: 1000 }}
      >
        <div
          className="bg-white p-6 shadow-lg text-center max-w-sm w-full"
          style={{
            position: 'fixed', 
            top: '18rem', 
            left: '50%',
            transform: 'translateX(-50%)', 
            borderRadius: '0',
            zIndex: 1001,
          }}
        >
          <p className="text-lg dark:text-black">Sign up process may not work on Firefox. Please use another browser.</p>
          <button
            onClick={() => setShowFirefoxDialog(false)}
            className="mt-4 bg-black text-white px-4 py-2"
            style={{
              borderRadius: '0',
              border: 'none',
            }}
          >
            Close
          </button>
        </div>
      </div>
    )}
  </>
  );
}
