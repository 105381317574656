import axios, { AxiosInstance } from 'axios';
import axiosRetry from 'axios-retry';

const axiosInstance: AxiosInstance = axios.create({
  baseURL: import.meta.env.VITE_PAYTRIE_API_URL,
  headers: {
    'x-api-key': import.meta.env.VITE_API_KEY,
  },
});
axiosRetry(axiosInstance);

export default axiosInstance;
