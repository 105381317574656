import { useEffect } from 'react';
import { Pages, usePageStateStore } from '../../reducers';
import { Button } from '../../components/Button/Button';
import { useNavigate } from 'react-router-dom';
import { Stepper } from '../../pages/Signup/Stepper/Stepper';
import {
  PaytrieTopIcon,
  PoweredByLabel,
  LoginHeader,
} from '../../components/widget-components';

export const CompleteRegistration = () => {
  const goToPage = usePageStateStore.use.goToPage();
  const navigate = useNavigate();

  const returnToHome = () => {
    navigate('/EasyLogin');
  }

  return (
    <div className="flex flex-col md:flex-row">
    <LoginHeader>
      <Stepper />
    </LoginHeader>
        <div className="relative p-5 lg:py-20 w-full">
        <PaytrieTopIcon className="absolute top-5 right-5 hidden md:block" />
        <div className="flex-grow mb-5">
          <div className="flex flex-col gap-5">

          <div className="text-2xl font-bold uppercase">Welcome to Paytrie!</div>
            <br />
            Your account has been verified.
            <br /><br /><br />
            <Button onClick={returnToHome}>
                CONTINUE TO DASHBOARD
            </Button>

          </div>

        </div>

      </div>
    </div>
  ); 


};
