import { useState } from 'react';
import { UploadID } from './UploadID';
import { SupportingDocuments } from './SupportingDocument';
import { SelfieUpload } from './SelfieUpload';
import { useSignupStore } from '../../../reducers';
import {
  ImageData,
  uploadImages,
  userVerification,
  amlCheck,
} from '../../../requests/signup';
import { isAxiosError } from 'axios';
import { LoadingIcon } from '../../../components/LoadingIcon';

export const ManualCheck = () => {
  const [files, setFiles] = useState<ImageData[]>([]);
  const [idType, setIdType] = useState('');
  const [idNumber, setIdNumber] = useState('');
  const [uploadCount, setUploadCount] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const nextState = useSignupStore.use.nextState();
  const complete = useSignupStore.use.complete();
  const setVerificationCode = useSignupStore.use.setVerificationCode();
  const store = useSignupStore();
  const { email, firstName, lastName } = store;

  const upload = async (
    fileData: ImageData,
    accountType: string,
    accountNumber: string,
    companyName: string
  ) => {
    const newFilesList = [...files, fileData];

    setIsLoading(true);
    const verifyResponse = await userVerification(email);
    if (verifyResponse) {
      // If user already verified go to complete
      complete();
    } else {
      // Do AML check first
      const amlCheckResp = await amlCheck(email, firstName, lastName);
      if (amlCheckResp) {
        const { response, error } = await uploadImages(
          email,
          newFilesList,
          idType,
          idNumber,
          accountType,
          accountNumber,
          companyName,
          amlCheckResp
        );
        if (response) {
          setVerificationCode(response.data.verification_code);
          nextState();
        }
        if (error && isAxiosError(error)) {
          setError(error.message);
        }
      }
    }
    setIsLoading(false);
  };

  if (isLoading) {
    return (
      <div className="my-auto flex justify-center">
        <LoadingIcon />
      </div>
    );
  }

  return (
    <div>
      <div className="flex justify-between">
        <div className="uppercase text-xl">Upload Documents</div>
        <div className="text-xs">{uploadCount}/3</div>
      </div>
      {error && <div className="text-rose-600">{error}</div>}
      {uploadCount === 1 && (
        <UploadID
          onConfirm={(fileData, idType, idNumber) => {
            setFiles((currentFiles) => [...currentFiles, fileData]);
            setIdType(idType);
            setIdNumber(idNumber);
            setUploadCount(2);
          }}
        />
      )}
      {uploadCount === 2 && (
        <SelfieUpload
          onConfirm={(fileData) => {
            setFiles((currentFiles) => [...currentFiles, fileData]);
            setUploadCount(3);
          }}
        />
      )}
      {/* {uploadCount === 2 && <SupportingDocuments onConfirm={upload} />} */}
      {uploadCount === 3 && (
        <SupportingDocuments
          onConfirm={(fileData, accountType, accountNumber, companyName) => {
            upload(fileData, accountType, accountNumber, companyName);
          }}
        />
      )}
    </div>
  );
};
