import Logo from '../assets/PT Logo.svg';

export const LoadingIcon = () => {
  return (
    <img
      src={Logo}
      height={50}
      width={50}
      className="animate-bounce invert dark:invert-0"
    />
  );
};
