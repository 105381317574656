import { useEffect, useState } from 'react';


export default function OliuRedirectFailed() {

    const urlParams = new URLSearchParams(window.location.search);
    const emailParams = urlParams.get("email") || localStorage.getItem('email');

    useEffect(() => {
        //window.parent?.postMessage(`redirectURL ${window.location.protocol}//${window.location.hostname}/SignupKYC?email=${emailParams}`)
        window.location.replace(`${window.location.protocol}//${window.location.hostname}/SignupKYC?email=${emailParams}`);  
    }, []);


return (
    <div>
        Redirecting to Paytrie. If you are not redirected automatically, click here: <a style={{textDecoration: 'underline'}} href={`${window.location.protocol}//${window.location.hostname}/SignupKYC?email=${emailParams}`}>{window.location.protocol}//{window.location.hostname}/SignupKYC?email={emailParams}</a>
    </div>
)
}