import { FormEvent, useState } from 'react';
import { FileUploader } from './FileUploader';
import { Button } from '../../../components/Button/Button';
import SelfieImage from '../../../assets/selfie.jpeg';
import { ImageData } from '../../../requests/signup';

type SelfieUploadProps = {
  onConfirm: (fileData: ImageData) => void;
};

export const SelfieUpload = ({ onConfirm }: SelfieUploadProps) => {
  const [error, setError] = useState('');
  const [fileData, setFileData] = useState<ImageData | null>();

  const handleSuccess = (fileData: ImageData) => {
    setFileData(fileData);
    setError('');
  };

  const handleConfirm = (e: FormEvent) => {
    e.preventDefault();
    if (fileData) {
      onConfirm(fileData);
    }
  };

  return (
    <form className="flex flex-col gap-5" onSubmit={handleConfirm}>
      <div>Please upload a photo with the following:</div>
      <div className="px-4">
        <p style={{ textAlign: 'left' }}>
          Clear image of yourself <b>HOLDING</b> the following 2 items
        </p>
        <div className="px-8">
          <ol className="list-decimal">
            <li>
              A clear image of your Photo ID (e.g. Drivers License/Passport)
            </li>
            <li>
              A handwritten note with the words "PayTrie" as well as
              today&apos;s date
            </li>
          </ol>
        </div>
      </div>
      {error && <div className="text-rose-600">{error}</div>}
      {fileData && (
        <div className="flex justify-center invisible md:visible">
          <img src={fileData.url} width={100} />
        </div>
      )}
      {!fileData && (
        <div className="hidden md:flex justify-center">
          <img src={SelfieImage} width={200} />
        </div>
      )}
      <FileUploader onUploadComplete={handleSuccess} onError={setError} customButtonText="" showUploadIcon={true} />
      <Button
        type="submit"
        onClick={handleConfirm}
        disabled={!fileData || !!error}
      >
        Confirm
      </Button>
    </form>
  );
};
